define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-follow-domain", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "rsvp", "discourse/lib/ajax", "discourse/lib/utilities", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _rsvp, _ajax, _utilities, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="activity-pub-follow-domain">
    <label>{{i18n "discourse_activity_pub.follow.domain.label"}}</label>
    <div class="activity-pub-follow-domain-controls inline-form">
      <Input
        {{on "keyup" this.onKeyup}}
        @value={{this.domain}}
        placeholder={{i18n "discourse_activity_pub.follow.domain.placeholder"}}
        id="activity_pub_follow_domain_input"
      />
      <DButton
        @icon="up-right-from-square"
        @action={{action "follow"}}
        @label="discourse_activity_pub.follow.domain.btn_label"
        @title="discourse_activity_pub.follow.domain.btn_title"
        @disabled={{this.verifying}}
        id="activity_pub_follow_domain_button"
      />
    </div>
    <div class={{this.footerClass}}>
      {{#if this.error}}
        {{this.error}}
      {{else if this.verifying}}
        {{i18n "discourse_activity_pub.follow.domain.verifying"}}
      {{else}}
        {{i18n "discourse_activity_pub.follow.domain.description"}}
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "nRCKjj4j",
    "block": "[[[10,0],[14,0,\"activity-pub-follow-domain\"],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,[28,[35,2],[\"discourse_activity_pub.follow.domain.label\"],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"activity-pub-follow-domain-controls inline-form\"],[12],[1,\"\\n    \"],[8,[39,3],[[16,\"placeholder\",[28,[37,2],[\"discourse_activity_pub.follow.domain.placeholder\"],null]],[24,1,\"activity_pub_follow_domain_input\"],[4,[38,4],[\"keyup\",[30,0,[\"onKeyup\"]]],null]],[[\"@value\"],[[30,0,[\"domain\"]]]],null],[1,\"\\n    \"],[8,[39,5],[[24,1,\"activity_pub_follow_domain_button\"]],[[\"@icon\",\"@action\",\"@label\",\"@title\",\"@disabled\"],[\"up-right-from-square\",[28,[37,6],[[30,0],\"follow\"],null],\"discourse_activity_pub.follow.domain.btn_label\",\"discourse_activity_pub.follow.domain.btn_title\",[30,0,[\"verifying\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"footerClass\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"error\"]],[[[1,\"      \"],[1,[30,0,[\"error\"]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"verifying\"]],[[[1,\"      \"],[1,[28,[35,2],[\"discourse_activity_pub.follow.domain.verifying\"],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,2],[\"discourse_activity_pub.follow.domain.description\"],null]],[1,\"\\n    \"]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"label\",\"i18n\",\"input\",\"on\",\"d-button\",\"action\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-follow-domain.hbs",
    "isStrictMode": false
  });

  // We're using a hardcoded url here as mastodon will only webfinger this as
  // an ostatus template if the account is local, which is too limiting.
  // See https://docs.joinmastodon.org/spec/webfinger
  // See https://socialhub.activitypub.rocks/t/what-is-the-current-spec-for-remote-follow/2020
  const mastodonFollowUrl = (domain, handle) => {
    return `https://${domain}/authorize_interaction?uri=${encodeURIComponent(handle)}`;
  };

  // See https://docs.joinmastodon.org/methods/instance/#v2
  const mastodonAboutPath = "api/v2/instance";
  class ActivityPubFollowDomain extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "verifying", [_tracking.tracked], function () {
      return false;
    }))();
    #verifying = (() => (dt7948.i(this, "verifying"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "error", [_tracking.tracked], function () {
      return null;
    }))();
    #error = (() => (dt7948.i(this, "error"), void 0))();
    get footerClass() {
      let result = "activity-pub-follow-domain-footer";
      if (this.error) {
        result += " error";
      }
      return result;
    }
    getFollowUrl(domain, handle) {
      return new _rsvp.Promise(resolve => {
        if (!(0, _utilities.hostnameValid)(domain)) {
          return resolve(null);
        }
        return (0, _ajax.ajax)(`https://${domain}/${mastodonAboutPath}`, {
          type: "GET",
          ignoreUnsent: false
        }).then(response => {
          if (response?.domain && response.domain === domain) {
            return resolve(mastodonFollowUrl(domain, handle));
          } else {
            return resolve(null);
          }
        }).catch(() => resolve(null));
      });
    }
    onKeyup(e) {
      if (e.key === "Enter") {
        this.follow();
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "onKeyup", [_object.action]))();
    async follow() {
      if (!this.domain) {
        return;
      }
      const handle = this.args.actor?.handle;
      if (!handle) {
        return;
      }
      this.error = null;
      this.verifying = true;
      const domain = (0, _utilities.extractDomainFromUrl)(this.domain);
      const url = await this.getFollowUrl(domain, handle);
      this.verifying = false;
      if (url) {
        window.open(url, "_blank")?.focus();
      } else {
        this.error = (0, _discourseI18n.i18n)("discourse_activity_pub.follow.domain.invalid");
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "follow", [_object.action]))();
  }
  _exports.default = ActivityPubFollowDomain;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ActivityPubFollowDomain);
});